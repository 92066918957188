import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[1]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[3]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[7]}")` }}
        ></figure>
        <div className="z-10 relative md:pt-[250px] pt-[300px] pb-[120px] w-4/5 mx-auto flex flex-wrap justify-center md:justify-between">
          <div className="md:w-1/2 w-full">
            <h1 className="text-white text-start">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-start">{rpdata?.dbValues?.[1].description}</p>
            <ButtonContent />
          </div>
          <div className="w-1/2 flex flex-wrap justify-center items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsello22%20google.png?alt=media&token=fd79370f-b2ac-414f-a4ce-d538c6ad19d5"
              alt="Not Found"
              className="w-[250px] mx-auto"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2FA%C3%B1os%20de%20experiencia%2F12_years_Mesa%20de%20trabajo%201.png?alt=media&token=79d0d642-edde-43a6-847b-87807785ad2e"
              alt="Not Found"
              className="w-[300px] mx-auto"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
